import ImgComponent from "../imgComponent"

const Banner = () => {
  return (
    <section
      className="elementor-element elementor-element-fb7667e elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-element_type="section"
      data-settings='{"stretch_section":"section-stretched","shape_divider_bottom":"curve","shape_divider_bottom_negative":"yes"}'
    >
      <div
        className="elementor-shape elementor-shape-bottom"
        data-negative="true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            className="elementor-shape-fill"
            d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
          />
        </svg>
      </div>
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-badcbdc elementor-column elementor-col-100 elementor-top-column"
            data-element_type="column"
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-7bef6e7 elementor-widget elementor-widget-avante-slider-parallax"
                  data-element_type="widget"
                  data-widget_type="avante-slider-parallax.default"
                >
                  <div className="elementor-widget-container">
                    <div
                      className="slider-parallax-wrapper"
                      data-autoplay={8000}
                      data-pagination={0}
                      data-navigation={1}
                    >
                      <div className="slider_parallax_inner">
                        <div className="slider_parallax_slides">
                          <div className="slide is-active ">
                            <div className="slide-content align-center">
                              <div className="standard-wrapper">
                                <div className="caption">
                                  <div className="title title-banner">
                                    <h2>Contact Us</h2>
                                  </div>
                                  <div className="text">
                                    We are here for you, reach us for any
                                    enquiries
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="image-container">
                              <ImgComponent
                                pmlImg="banner_contactus.jpg"
                                className="image"
                              />
                              {/* <img
																// ** //
																src={img01}
																alt=""
																className="image"
															/> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner

// import React from 'react';

// import ImgComponent from '../imgComponent';

// const Banner = () => {
// 	return (
// 		<section
// 			className="elementor-element elementor-element-172e078 elementor-section-stretched elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section"
// 			data-id="172e078"
// 			data-element_type="section"
// 			data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;,&quot;shape_divider_bottom&quot;:&quot;curve&quot;,&quot;shape_divider_bottom_negative&quot;:&quot;yes&quot;}"
// 		>
// 			<div className="elementor-background-overlay" />
// 			<div className="elementor-shape elementor-shape-bottom" data-negative="true">
// 				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
// 					<path
// 						className="elementor-shape-fill"
// 						d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
// 					/>
// 				</svg>
// 			</div>
// 			<div className="elementor-container elementor-column-gap-default">
// 				<div className="elementor-row">
// 					<div
// 						className="elementor-element elementor-element-05f8f5e elementor-column elementor-col-100 elementor-top-column"
// 						data-id="05f8f5e"
// 						data-element_type="column"
// 						data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
// 					>
// 						<div className="elementor-column-wrap elementor-element-populated">
// 							<div className="elementor-widget-wrap">
// 								<div
// 									className="elementor-element elementor-element-1ec7424 elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading"
// 									data-id="1ec7424"
// 									data-element_type="widget"
// 									data-settings="{&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]},&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewx&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewy&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_perspective&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]},&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
// 									data-widget_type="heading.default"
// 								>
// 									<div className="elementor-widget-container contactus-banner-size">
// 										<h2 className="elementor-heading-title elementor-size-default" id="font-subs">Contact Us</h2>
// 									</div>
// 								</div>
// 								<div
// 									className="elementor-element elementor-element-7279b2d elementor-widget elementor-widget-text-editor"
// 									data-id="7279b2d"
// 									data-element_type="widget"
// 									data-settings="{&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]},&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_scaley&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatey&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_rotatez&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_translatex&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewx&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_skewy&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;avante_ext_smoove_perspective&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]},&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
// 									data-widget_type="text-editor.default"
// 								>
// 									<div className="elementor-widget-container">
// 										<div className="elementor-text-editor elementor-clearfix">
// 											<div className="lqd-lines split-unit lqd-unit-animation-done" id="font-contactus-banner">
// 											We are here for you, reach us for any enquiries
// 											</div>
// 										</div>

// 									</div>
// 								</div>

// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</section>
// 	);
// };

// export default Banner;
